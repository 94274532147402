<template>
  <el-row class="table">
    <span class="title">模板管理</span>
    <el-row class="row-bg" :gutter="24">
      <el-col :span="3">
        <el-input
          placeholder="输入搜索模板标题"
          v-model.trim="moduleTitle"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-input
          placeholder="输入搜索创建人姓名"
          v-model.trim="creatorName"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>
      <!-- <el-col :span="4">
        <el-select v-model="scrachType" placeholder="请选择搜索类型">
          <el-option
            v-for="item in scratchOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-col> -->
      <el-col :span="14">
        <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
        <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
        <el-button type="primary" size="medium" @click="add" class="tb-button">新增scratch模板</el-button>
      </el-col>

    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
      style="width: 100%;"
    >
      <el-table-column label="模板标题" align="center" prop="title"></el-table-column>
      <el-table-column label="创建人" align="center" prop="creatorName"></el-table-column>
      <el-table-column label="模板类型" align="center" prop="type"></el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- add -->
    <add-scratch-modle
      :show="addMoudleDialog"
      @close="addMoudleDialog=false"
      @scratchmoduleList="scratchmoduleList"
      title="新增模板"
    ></add-scratch-modle>

       <!-- <el-dialog
      :visible.sync="scratchJrModalVisible"
      :fullscreen="true"
      :modal="false"
      :lock-scroll="true"
      :destroy-on-close="true"
      class="mould-style"
    >
      <ScratchJrMould  :currentProjectInfo='currentProjectInfo' @getScratchJrInfo='getScratchJrInfo' v-if='scratchJrModalVisible'/>
    </el-dialog> -->
  </el-row>
</template>

<script>
import AddScratchModle from '@/components/research/quesbank-manage/addScratchModle'
import {scratchmoduleList, scratchmoduleDelect} from '@/api/research/scratch'
import {getToken} from '@/utils/auth'
import ScratchJrMould from '@/views/ScratchJr/mould'

export default {
  components: {
    AddScratchModle,
    ScratchJrMould,
  },
  data() {
    return {
      scratchJrModalVisible:false,
      scrachType: '',
      scratchOptions: [
        {
          value: 'scratchjr',
          label: 'scratchJr',
        }, {
          value: 'scratch',
          label: 'scratch',
        }
      ],
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      addMoudleDialog: false,
      moduleTitle: "",
      creatorName: "",
      currentProjectInfo:{},
    }
  },
  created() {
    this.scratchmoduleList()
  },
  methods: {
    // 获取模板列表
    async scratchmoduleList() {
      const response = await scratchmoduleList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        title: this.moduleTitle,
        creatorName: this.creatorName,
        type: 'scratch'
      })
      this.dataList = response.body.list
      this.pagination.total = response.body.total
    },
    async scratchmoduleDelect(id) {
      const response = await scratchmoduleDelect({
        id: id,
      })
      if (response.state === "success") {
        $msg("删除成功")
        this.scratchmoduleList()
      } else {
        $msg("删除失败")
      }
    },
    // 重置
    reset() {
      this.moduleTitle = ""
      this.creatorName = ""
      this.scrachType = ""
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1
      this.scratchmoduleList()
    },

    add() {
      this.addMoudleDialog = true
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.scratchmoduleList()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.scratchmoduleList()
    },

    // 编辑
    edit(row) {
      if (row.type === 'scratchjr') {
        this.currentProjectInfo = row;
        const routeData = this.$router.resolve({
          name: 'ScratchJrMould',
          query: {
            ...row,
          }
        })
        window.open(routeData.href, "_blank")
      } else if (row.type === 'scratch') {
        const routeData = this.$router.resolve({
          path: '/research/editScratchMould',
          query: {
            file: row.file,
            pageType: "editmoudl",
            moudlid: row.id,
            fileName: row.title,
            token: getToken()
          }
        })
        window.open(routeData.href, "_blank")
      }
    },

    // 删除
    deleteItem(row) {
      this.$confirm('是否删除当前模板', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.scratchmoduleDelect(row.id)
        })
        .catch(() => {
          return false
        })
    },
    getScratchJrInfo(val){
        if(val){
          this.scratchJrModalVisible = false;
          this.scratchmoduleList();
          this.$message.success('保存成功！');
        }else{
          this.$message.info('保存失败请重试！');
        }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
<style  lang="scss">
.mould-style{
  .el-dialog__header {
    padding: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    right: 0;
    .el-dialog__headerbtn {
      position: absolute;
      right: 50px;
      top: 12px;
      color: #000;
      z-index: 99999;
    }
  }
  .el-icon-close:before{
    width: 20px;
    height: 20px;
    content: 'X';
  }
  .el-icon-close:hover{
    color: #000;
  }
  .el-dialog__body {
    padding: 0 !important;
    width: calc(100vw);
    height: calc(100vh);
    overflow: hidden;
  }
}
</style>